import { Component, Prop, Vue } from 'vue-property-decorator'
import { Resource } from '@/entities/public'
import { mapActions, mapGetters } from 'vuex'
import { Form } from '@/entities/public/Resource/metadata'
import { MissingAPI, UnregisteredQuery } from '@/errors'
import { Query } from '@/utils/computed/Query'
import { Query as IQuery } from '@/entities/public/Resource/interfaces'
import { formFilter } from '@/graphql/generated-types'
import { InputGroup } from '@/entities/public/Resource/interfaces/form'
import { getObjectValueByPath } from '@/utils/vuetify/helpers'
import { setValueByPath } from '@/utils/data'
import { typeValue } from '@/utils/general'

@Component({
  methods: {
    ...mapActions('resources/form', ['fetchData']),
  },
  computed: {
    ...mapGetters('resources', ['getForm']),
  },
})
export class FormStructure extends Vue {
  @Prop({ type: String, required: true }) model!: string
  @Prop({ type: String, required: true }) slug!: string
  resource: Resource
  getForm!: (model: string, slug?: string) => Resource
  fetchData!: (payload: {
    query: IQuery
    filter?: formFilter
    force?: boolean
    limit?: number
    params?: any
    offset?: number
    distinct?: Array<string>
  }) => Promise<any>

  get structure (): Form {
    const { resource } = this
    if (resource) return resource.metadata as Form

    const { model, slug } = this
    const { metadata } = this.resource = this.getForm(model, slug)
    return metadata as Form
  }

  get layout () {
    const { structure: { layout, fields }, fetch } = this
    return { ...layout, fields, fetch }
  }

  get fields () {
    const { structure: { fields } } = this
    return fields
  }

  get fetch () {
    const { structure: { api } } = this
    if (!api) throw new MissingAPI()
    if (!api.queries?.length) {
      return (q: Query) => {
        throw new UnregisteredQuery(q)
      }
    }

    const fetch = this.fetchData
    return ({ uid, filter, params, distinct }: Query) => {
      const q = api.queries.find(q => q.uid === uid)
      if (!q) throw new UnregisteredQuery({ uid })

      return fetch({ query: q, filter, params, distinct })
    }
  }

  get groups () {
    const { fields } = this

    const groups: Record<string, InputGroup> = {}
    Object.entries(fields).forEach(([key, { group, properties: { name: field } }]) => {
      if (!group?.name || !field) return

      const { name, condition } = group
      if (!condition) throw new Error(`Missing condition for group: "${name}" at field: "${field}"`)
      setValueByPath(groups, `${name}.${field}`, condition)
    }
    )

    return groups
  }

  async mapFields (val: any, map = [], fields = this.structure.auditFields) {
    const { model } = this
    if (val?.constructor.name !== model) return
    const fetch = this.fetch

    await Promise.all(Object.entries(fields).map(async ([key, { target, properties, computed, component }]) => {
      if (!properties?.label) return map
      const { label, itemText, itemValue } = properties

      if (itemValue && computed && 'relation' in computed?.queries) {
        if (!val[properties.name]) return
        const {
          queries: {
            relation,
          },
        } = computed
        const q = new Query(relation, val, fetch)
        setValueByPath(val, target, await q.value())
      }
      const path = itemText ? `${target}.${itemText}` : target

      const value = getObjectValueByPath(val, path)
      if (!value) return

      map.push({
        value,
        type: typeValue(value),
        label,
        component,
      })
    })
    )

    return map
  }
}
