
  import { Component, Prop } from 'vue-property-decorator'
  import { FormStructure } from '@/components/forms/FormStructure'
  import { mapActions } from 'vuex'
  import { plainToInstance } from 'class-transformer'

  import { deepCopy, fieldCast, isEmpty } from '@/utils/general'
  import { findDifferences } from '@/utils/vuetify/helpers'
  import { buildCondition } from '@/utils/conditional'

  import { extract } from '@/graphql/forms'
  import { InvalidForm } from '@/errors'

  import DynamicLayout from './DynamicLayout.vue'

  import { ButtonType } from '@/entities/public/Resource/interfaces/form'
  import { insertInput, updateInput } from '@/graphql/generated-types'
  import { Generic } from '@/entities/generic'

@Component({
  components: {
    Layout: DynamicLayout,
  },
  methods: {
    ...mapActions('resources/form', ['pushData']),
  },
})
  export default class DynamicForm extends FormStructure {
  $refs!: {
    form: HTMLFormElement;
    layout: DynamicLayout
  };

  @Prop({ type: String }) name?: string
  @Prop({ type: Object, default: () => ({}) }) readonly value!: any
  @Prop({ type: [String, Number] }) uid!: any
  @Prop({ type: Number }) parentId?: number
  @Prop({ type: String }) parentModel?: string
  @Prop({ type: Boolean, default: false }) preventRemote: boolean
  @Prop({ type: Boolean, default: false }) child: boolean
  @Prop({ type: Boolean, default: true }) visible: boolean
  @Prop({ type: Boolean, default: false }) disabled!: boolean
  @Prop({ type: Boolean, default: false }) containerPadding: boolean

  valid = false
  success = ''
  backup?: any

  pushData!: (payload: { model: string, fields?: insertInput | updateInput }) => Promise<any>

  async created () {
    const { structure: { api: { model } } } = this
    const { Model } = extract({ name: 'fetch', model })

    const { value, parentModel, parentId } = this

    let parent

    if (parentModel && parentId) {
      parent = await this.fetchData({ query: { name: 'fetch', model: parentModel, params: { id: parentId } } })
    }
    if (parent) {
      Object.assign(value, { [parentModel.toLowerCase()]: parent })
    }
    if (value.constructor === Model) return this.saveBackup(value)
    const { uid } = this
    const val = !uid
      ? plainToInstance(Model, Generic.init(deepCopy(value), this.fields))
      : await this.fetchData({ query: { name: 'fetch', model, params: { id: uid } } })

    // TODO: Should we initialize on edit forms?
    if (uid) this.saveBackup(val)
    this.set({ value: val })
  }

  async onParentChange (): Promise<void> {
    await this.onModelChanged(this.value)
  }

  mounted () {
    if (this.preventRemote && this.$refs.form) this.$refs.form.resetValidation()
  }

  private saveBackup (value) {
    const backup = plainToInstance(value.constructor, deepCopy(value))
    if ('serialized' in value) Object.defineProperty(backup, 'serialized', { value: value.serialized, writable: true })

    this.backup = backup
  }

  set (newVal) {
    this.$emit('update', newVal)
  }

  async onModelChanged (value): Promise<boolean> {
    const { structure: { form: { forceRender } } } = this
    await this.$refs.layout?.childrenUpdate(value, forceRender)
    return this.valid
  }

  private setGroups (fields) {
    if (fields.id) return fields
    const { groups } = this

    Object.entries(groups).forEach(([name, conditions]) => {
      const group = []

      Object.entries(conditions).map(([field, statement]) => {
        const value = fields[field]
        if (!value?.data) return

        if (buildCondition(value.data, statement).result) group.push(value.data)
        delete fields[field]
      })

      if (group.length) fields[name] = { data: group }
    })

    return fields
  }

  public serialized (): Record<string, any> {
    const target = this.$refs.form.$el
    const { value } = this
    const fields = { id: value.id }

    const form = new FormData(target)
    form.forEach((value, key) => !isEmpty(value) && (fields[key] = fieldCast(value)))

    const { structure: { form: { condition } } } = this
    if (condition && !buildCondition(fields, condition).result) throw new InvalidForm(this.name || value.constructor.name)

    if ('serialized' in value) value.serialized = this.setGroups(fields)
    else Object.defineProperty(value, 'serialized', { value: this.setGroups(fields), writable: true })

    return value.serialized
  }

  async submit (type: ButtonType): Promise<void> {
    if (type === 'cancel') {
      this.$emit('cancel')
      return
    }
    if (type !== 'submit') return

    const { preventRemote } = this
    if (!this.$refs.form.validate()) {
      const error = new InvalidForm(this.name || this.value.constructor.name)

      if (preventRemote) throw error
      else this.$emit('fail', error)

      return
    }

    try {
      await this.$refs.layout.submit(type)

      const { structure: { form: { parent } }, backup, value } = this
      if (backup && value[parent]?.id) {
        const exclude = new Set([
          parent,
          ...Object.entries(this.fields).map(([key, {
            component,
            target,
            group,
          }]) => component === 'form' && [target, group?.name]).flat(),
        ])

        if (!findDifferences(value, backup, exclude).length) return
      }

      const { structure: { api: { model } } } = this
      const fields = this.serialized()

      if (!preventRemote || fields.id || value[parent]?.id) {
        this.set({ value: await this.pushData({ model, fields }) })
      } else this.success = JSON.stringify({ data: fields })

      this.$emit('success', fields)
    } catch (e) {
      this.success = ''
      this.$emit('fail', e)
      if (!(e instanceof InvalidForm)) throw e
    }
  }

  cancel () {
    const { backup } = this
    this.set({ value: backup })
  }
  }
