
  import { Component, Prop, Vue } from 'vue-property-decorator'
  import MobileForm from '@/components/forms/view/MobileForm.vue'
  import FormTitle from '@/components/forms/FormTitle.vue'
  import DesktopForm from '@/components/forms/view/DesktopForm.vue'
  import { ActivityType } from '@/entities/crm'
  import { mapGetters } from 'vuex'

@Component({
  components: {
    MobileForm,
    FormTitle,
    DesktopForm,
  },
  methods: {},
  computed: {
    ...mapGetters('app', ['isMobile']),
  },
})
  export default class BaseForm extends Vue {
  @Prop({ type: Boolean, default: false }) showHistory!: boolean
  @Prop({ type: Boolean, default: false }) dense!: boolean
  @Prop({ type: Object }) metadata!: any
  @Prop({ type: Number }) containerHeight!: number
  @Prop({ type: Boolean, default: true }) hideDetails!: boolean
  @Prop({ type: Boolean, default: false }) hideTitle!: boolean
  @Prop({ type: String }) model!: string
  @Prop({ type: String, default: '' }) slug!: string
  @Prop({ type: String }) title!: string
  @Prop({ type: String, default: '' }) subtitle!: string
  @Prop({ type: Number }) id!: number
  @Prop({ type: [Object, String, Number, Array] }) change: object | string | number | Array<Record<string, any>>
  @Prop({ type: Number }) uid!: number
  @Prop({ type: Object, default: null }) isAgenda!: { title: ActivityType, date: string, allowSelect: boolean }
  isMobile!: boolean
  showTitle: boolean = true

  get modelNotCapitalize () {
    const { model, slug } = this

    return slug?.length ? slug : model.toLowerCase()
  }

  setHideTitle (val) {
    if (!this.hideTitle) {
      return false
    }
    this.showTitle = !val
  }
  }
