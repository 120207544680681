export class Correlation {
  _perc = 0
  _amount = 0

  constructor (private readonly total: number, initial) {
    this.perc = initial ?? 0
  }

  get debt () {
    return this.total - this.amount
  }

  get perc () {
    return Number((this._perc * 100).toFixed(2))
  }

  set perc (perc) {
    this.amount = 0
    this._perc = perc / 100
    this._amount = this.total * this._perc
  }

  get amount () {
    return Number(Number(this._amount).toFixed(2))
  }

  set amount (amount) {
    this._amount = amount
    this._perc = amount / this.total
  }
}
