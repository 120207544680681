import { Component, Prop, Vue } from 'vue-property-decorator'

import { ComputedProps, ComputedQuery, InputComponent } from '@/entities/public/Resource/interfaces/form'
import { debounce, debouncePromise, getObjectValueByPath } from '@/utils/vuetify/helpers'
import { buildCondition, Condition } from '@/utils/conditional'
import { Query } from '@/utils/computed/Query'

@Component
export class Dynamic extends Vue {
  @Prop({ type: Object }) readonly value!: any
  @Prop({ type: String }) component!: InputComponent;
  @Prop({ type: Object, default: () => ({}) }) properties!: any;
  @Prop({ type: Object }) computed!: ComputedProps

  @Prop({ type: Function }) fetch!: (query: ComputedQuery) => Promise<any>

  bind: Record<string, any> = {}
  triggers: Record<string, (v: string) => void> = {}

  forceUpdate: () => void

  created () {
    this.forceUpdate = debounce(() => this.$forceUpdate(), 100)
  }

  async onChangeValue (value) {
    if (!value) return

    this.updateBind()
    await this.updateQueries()
  }

  notifyChange (value, target?) {
    if (!target) return this.$emit('update', value)
    this.$emit('update', { path: target, value })
  }

  get extracted () {
    const { computed } = this
    if (!computed?.extracted) return

    const { value } = this
    const { extracted } = computed
    const props = {}

    Object.entries(extracted)
      .forEach(([prop, path]) =>
        Object.defineProperty(props, prop, { get () { return getObjectValueByPath(value, path) }, enumerable: true }))

    if ('__props__' in extracted) Object.assign(props, getObjectValueByPath(value, extracted.__props__))

    return props
  }

  get conditions (): Record<string, Condition> {
    const { computed } = this
    if (!computed?.conditions) return

    const { value } = this
    const { conditions } = computed
    const _conditions = {}

    Object.entries(conditions)
      .forEach(([prop, condition]) =>
        _conditions[prop] = buildCondition(value, condition))

    return _conditions
  }

  updateBind () {
    const { conditions, extracted } = this
    if (conditions) Object.entries(conditions).forEach(([prop, condition]) => this.$set(this.bind, prop, condition.result))
    if (extracted) Object.entries(extracted).forEach(([prop, value]) => this.$set(this.bind, prop, value))
  }

  async updateQuery ([prop, query]: [string, Query]) {
    if (!query.filled || query.cache) return

    this.$set(this.bind, 'loading', true)
    this.$set(this.bind, prop, await query.value())
    this.$set(this.bind, 'loading', false)
  }

  get queries (): Record<string, Query> {
    const { computed } = this
    if (!computed?.queries) return

    const { value } = this
    const { queries } = computed
    const props = {}

    Object.entries(queries)
      .forEach(([prop, query]) => {
        const q = new Query(query, value, this.fetch)
        q.update = debouncePromise(() => this.updateQuery([prop, q]), 10)
        props[prop] = q
      })

    return props
  }

  async updateQueries () {
    const { queries } = this
    if (!queries) return

    await Promise.all(Object.entries(queries).map(([prop, q]) => q.update()))
  }
}
