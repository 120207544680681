
  import { Component, Prop, Watch } from 'vue-property-decorator'
  import Cell from './cell'
  import { Route } from 'vue-router'
  import { isValidNumber } from '@/utils/general'

@Component
  export default class ProgressCell extends Cell {
  @Prop({ type: Boolean, default: true }) round!: boolean
  @Prop({ type: Boolean, default: false }) inverted!: boolean
  @Prop({ type: String, default: '' }) title!: string

  progress = 0
  supervisor = false

  get color () {
    const { item, isSupervisor, inverted } = this

    if (!item) {
      this.progress = 0
      return 'grey'
    }

    if (isSupervisor) {
      this.progress = item?.supervisorMetadata?.totalCategoriesValidated || 0
    } else {
      this.progress = isValidNumber(item.metadata?.progress) ? item?.metadata?.progress : 0
    }

    if (!inverted) {
      switch (true) {
        case (this.progress >= 0 && this.progress < 6.4):
          return 'grey'
        case (this.progress >= 6.6 && this.progress < 35):
          return 'red'
        case (this.progress >= 35 && this.progress < 50):
          return 'orange'
        case (this.progress >= 50 && this.progress < 72):
          return 'yellow'
        case (this.progress >= 72 && this.progress < 93):
          return 'green'
        default:
          return 'primary'
      }
    } else {
      switch (true) {
        case (this.progress >= 0 && this.progress < 6.4):
          return 'primary'
        case (this.progress >= 6.6 && this.progress < 35):
          return 'green'
        case (this.progress >= 35 && this.progress < 50):
          return 'yellow'
        case (this.progress >= 50 && this.progress < 72):
          return 'orange'
        case (this.progress >= 72 && this.progress < 93):
          return 'red'
        default:
          return 'black'
      }
    }
  }

  get isSupervisor () {
    const { options } = this

    return options?.supervisor
  }

  @Watch('$route', { immediate: true, deep: true })
  onChangeRoute (val: Route): void {
    this.supervisor = val.name.includes('supervisor') || val.params?.role === 'supervisor'
  }
  }
